export default {
  lutrijabosnaihercegovina: {
    staging: {
      api: 'https://sports-sm-distribution-api.staging.de-2.nsoftcdn.com',
      ticketApi: 'https://sports-sm-ticket.staging.de-2.nsoftcdn.com',
      distributionSocket: 'https://sports-sm-distribution-socket.staging.de-2.nsoftcdn.com',
      smGateway: 'https://sports-sm-gateway.staging.de-2.nsoftcdn.com',
      smTicketSocket: 'https://sports-sm-ticket-socket.staging.de-2.nsoftcdn.com',
      smTicketCalculationSocket: 'https://sports-sm-ticket-calculator.staging.de-2.nsoftcdn.com',
      gravityApi: 'https://staging-gravity-service.7platform.com',
      tc: 'https://tc-service.gb.nsoftcdn.com/v2/b2b/translations',
      reporting: 'https://sports-sm-reporting-api.staging.de-2.nsoftcdn.com/api/admin/report',
      sevenApi: 'https://services-staging.7platform.com',
      scoutPlugin: 'https://staging.sports-scout.7platform.net',
      liveSocket: 'https://sports-mts-api.staging.gb.nsoftcdn.com',
      liveTickets: 'https://services-staging.7platform.com/web',
      liveCashout: 'https://sports-mts-cashout.staging.gb.nsoftcdn.com',
      liveCustomBet: 'https://sports-mts-api.staging.gb.nsoftcdn.com',
      prematchCashout: 'https://sports-sm-cashout.staging.de-2.nsoftcdn.com',
      cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/staging',
    },
    production: {
      api: 'https://sportsbook-sm-distribution-api.nsoft.com',
      ticketApi: 'https://sportsbook-sm-ticket.lutrijabih.ba',
      distributionSocket: 'https://sportsbook-sm-distribution-socket.nsoft.com',
      smGateway: 'https://sportsbook-sm-gateway.lutrijabih.ba',
      smTicketSocket: 'https://sportsbook-sm-ticket-socket.lutrijabih.ba',
      smTicketCalculationSocket: 'https://sportsbook-sm-ticket-calculator.lutrijabih.ba',
      gravityApi: 'https://gravity.de.nsoftcdn.com',
      tc: 'https://tc-service.gb.nsoftcdn.com/v2/b2b/translations',
      reporting: 'https://sportsbook-sm-reporting-api.lutrijabih.ba/api/admin/report',
      sevenApi: 'https://menhir.de.nsoftcdn.com',
      scoutPlugin: 'https://sports-scout.7platform.net',
      liveSocket: 'https://sports-mts-api.gb.nsoftcdn.com',
      liveTickets: 'https://menhir.de.nsoftcdn.com/web',
      liveCashout: 'https://sports-mts-cashout.gb.nsoftcdn.com',
      liveCustomBet: 'https://sports-mts-api.gb.nsoftcdn.com',
      prematchCashout: 'https://sports-sm-cashout.lutrijabih.ba',
    },
  },
};
