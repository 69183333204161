import env from './env';
import companyEnv from './companyEnv';
import integrationsEnv from './integrationsEnv';
import appConfig from './config';
import queryParser from '../src/utility/queryParser';

const companyName = queryParser.getUrlParameter('companyName');

const serverEnv = process.env.SERVER;

const activeEnv = serverEnv; // activeEnv is used in client-store-app

const companyRoutes = (companyEnv[companyName] || {})[activeEnv];
const integrationsApi = (integrationsEnv[companyName] || {})[activeEnv];

const routes = companyRoutes || env[activeEnv];
routes.integrationsApi = integrationsApi || '';

export {
  routes,
  appConfig,
  serverEnv,
  activeEnv,
  companyName,
};
