export default {
  desktop: {
    ThemeImporter: () => import(/* webpackChunkName: "powerbet-themeWrap" */'./ThemeImporter'),
    LiveThemeImporter: () => import(/* webpackChunkName: "powerbet-themeWrapLive" */'./LiveThemeImporter'),
  },
  mobile: {
    LiveThemeImporter: () => import(/* webpackChunkName: "powerbet-themeWrapLive" */'./LiveThemeImporter'),
    ThemeImporter: () => import(/* webpackChunkName: "powerbet-themeWrap" */'./ThemeImporter'),
  },
};
