import isMobile from './isMobile';

const mobileDetect = {
  computed: {
    $isMobile() {
      return isMobile();
    },
    $isIOS() {
      return !!(navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i));
    },
    $isAndroid() {
      return !!navigator.userAgent.match(/Android/i);
    },
  },
};

export default mobileDetect;
