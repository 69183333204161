export default {
  desktop: {
    ThemeImporter: () => import(/* webpackChunkName: "prvafirma-themeWrap" */'./ThemeImporter'),
    LiveThemeImporter: () => import(/* webpackChunkName: "prvafirma-themeWrapLive" */'./LiveThemeImporter'),
  },
  mobile: {
    ThemeImporter: () => import(/* webpackChunkName: "prvafirma-themeWrap" */'./ThemeImporter'),
    LiveThemeImporter: () => import(/* webpackChunkName: "prvafirma-themeWrapLive" */'./LiveThemeImporter'),
  },
};
