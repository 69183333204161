const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

const getCurrentTime = timezone => moment()
  .tz(timezone || 'UTC')
  .format(dateFormat);

const getStartOfDayTime = (timezone, daysToAdd) => moment()
  .tz(timezone || 'UTC')
  .add(Number(daysToAdd || 0), 'days')
  .startOf('day')
  .format(dateFormat);

const getEndOfDayTime = (timezone, daysToAdd) => moment()
  .tz(timezone || 'UTC')
  .add(Number(daysToAdd || 0), 'days')
  .endOf('day')
  .format(dateFormat);

const getCurrentDayTimeWithHours = (timezone, hoursToAdd) => moment()
  .tz(timezone || 'UTC')
  .add(Number(hoursToAdd || 0), 'hours')
  .format(dateFormat);

const generateTodayDateRangeValues = timezone => ({
  timeSpan: 'today',
  filterValues: {
    from: getCurrentTime(timezone),
    to: getEndOfDayTime(timezone, 0),
  },
});

const generateAllDateRangeValues = (timezone, offerRange = {}) => ({
  timeSpan: 'all',
  filterValues: {
    from: getCurrentTime(timezone),
    to: offerRange.loadFullOffer ? '' : getEndOfDayTime(timezone, offerRange.days),
  },
});

const generateSpecificDateRangeValues = (timezone, daysToAdd) => ({
  filterValues: {
    from: getStartOfDayTime(timezone, daysToAdd),
    to: getEndOfDayTime(timezone, daysToAdd),
  },
});

const generateComingSoonDateRangeValues = ({ timeSpan, timezone }) => ({
  timeSpan: 'comingSoon',
  filterValues: {
    from: getCurrentTime(timezone),
    to: timeSpan ? getCurrentDayTimeWithHours(timezone, timeSpan) : '',
  },
});

export default {
  /**
   *
   * @param {string} timeValue
   * @param {string} timeSpan
   * @param {string} timezone
   * @param {object} offerDateRange
   * @returns {object}
   */
  generateTimeRange({
    timeValue,
    timeSpan,
    timezone,
    offerDateRange,
    daysToAdd,
  } = {}) {
    switch (timeValue) {
      case 'today': return generateTodayDateRangeValues(timezone);
      case 'all': return generateAllDateRangeValues(timezone, offerDateRange);
      case 'dayInWeek': return generateSpecificDateRangeValues(timezone, daysToAdd);
      case 'comingSoon': return generateComingSoonDateRangeValues({ timeSpan, timezone });
      default: return generateAllDateRangeValues(timezone, offerDateRange);
    }
  },
  /**
   *
   * @param {number} timeSpanValue Number of hours to calculate
   * @param {string} timeSpanName Display name for time-span
   * @param {string} timezone
   * @returns {object}
   */
  generateCustomTimeRange({ timeSpanValue, timeSpanName, timezone }) {
    return {
      timeSpan: timeSpanName || '',
      filterValues: {
        from: getCurrentTime(timezone),
        to: getCurrentDayTimeWithHours(timezone, timeSpanValue),
      },
    };
  },
};
