export default {
  filterDayOptions: [{
    name: 'monday',
    label: 'day_abbreviation_monday',
    optionType: 'day',
    isoWeekday: '1',
    active: true,
  },
  {
    name: 'tuesday',
    label: 'day_abbreviation_tuesday',
    optionType: 'day',
    isoWeekday: '2',
    active: true,
  },
  {
    name: 'wednesday',
    label: 'day_abbreviation_wednesday',
    optionType: 'day',
    isoWeekday: '3',
    active: true,
  },
  {
    name: 'thursday',
    label: 'day_abbreviation_thursday',
    optionType: 'day',
    isoWeekday: '4',
    active: true,
  },
  {
    name: 'friday',
    label: 'day_abbreviation_friday',
    optionType: 'day',
    isoWeekday: '5',
    active: true,
  },
  {
    name: 'saturday',
    label: 'day_abbreviation_saturday',
    optionType: 'day',
    isoWeekday: '6',
    active: true,
  },
  {
    name: 'sunday',
    label: 'day_abbreviation_sunday',
    optionType: 'day',
    isoWeekday: '7',
    active: true,
  },
  {
    name: 'all',
    label: 'general_all',
    optionType: 'time',
    timeSpan: '-1',
    active: true,
  },
  {
    name: 'nextDays',
    label: 'next_days',
    optionType: 'time',
    addValue: 24,
    timeSpan: `${24 * 1}`,
    active: true,
  },
  {
    name: 'today',
    label: 'general_today',
    optionType: 'time',
    timeSpan: '24',
    active: true,
  },
  {
    name: 'comingSoon',
    label: 'coming_soon',
    optionType: 'time',
    addValue: 1,
    timeSpan: '4',
    active: true,
  },
  {
    name: 'link',
    label: 'live_betting',
    active: false,
    optionType: 'link',
  },
  ],
};
