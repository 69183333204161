export default {
  prvafirma: {
    staging: 'https://int-oddin-prva-firma.staging.de-3.nsoft.cloud',
    production: 'https://int-oddin-prva-firma.staging.de-3.nsoft.cloud',
  },
  balkanbet: {
    staging: 'https://int-oddin-balkan-bet.staging.de-3.nsoft.cloud',
    production: 'https://int-oddin-balkan-bet.staging.de-3.nsoft.cloud',
  },
  simacommunication: {
    production: 'https://int-oddin-sima.de-3.nsoft.cloud',
  },
};
