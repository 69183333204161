export default {
  desktop: {
    LiveThemeImporter: () => import(/* webpackChunkName: "damagi-dark-themeWrapLive" */'./LiveThemeImporter'),
    LiveLightThemeImporter: () => import(/* webpackChunkName: "damagi-light-themeWrapLive" */'./LiveLightThemeImporter'),
    HappySpinsLiveDarkThemeImporter: () => import(/* webpackChunkName: "happySpins-themeWrapLive" */'./HappySpinsLiveDarkThemeImporter'),
    YaacasinoLiveLightThemeImporter: () => import(/* webpackChunkName: "yaacasino-themeWrapLive" */'./YaacasinoLiveLightThemeImporter'),
    MoicasinoLiveLightThemeImporter: () => import(/* webpackChunkName: "moicasino-themeWrapLive" */'./MoiCasinoLiveLightThemeImporter'),
    HeyCasinoLiveDarkThemeImporter: () => import(/* webpackChunkName: "heycasino-themeWrapLive" */'./HeyCasinoLiveDarkThemeImporter'),
    LyraCasinoLiveDarkThemeImporter: () => import(/* webpackChunkName: "lyracasino-themeWrapLive" */'./LyraCasinoLiveDarkThemeImporter'),
    SlotParadiseLiveDarkThemeImporter: () => import(/* webpackChunkName: "slotparadise-themeWrapLive" */'./SlotParadiseLiveDarkThemeImporter'),
    QuickSlotsLiveLightThemeImporter: () => import(/* webpackChunkName: "quickslots-themeWrapLive" */'./QuickSlotsLiveLightThemeImporter'),
    ProntoBetLiveDarkThemeImporter: () => import(/* webpackChunkName: "prontobet-themeWrapLive" */'./ProntoBetLiveDarkThemeImporter'),
  },
  mobile: {
    LiveThemeImporter: () => import(/* webpackChunkName: "damagi-dark-themeWrapLive" */'./LiveThemeImporter'),
    LiveLightThemeImporter: () => import(/* webpackChunkName: "damagi-light-themeWrapLive" */'./LiveLightThemeImporter'),
    HappySpinsLiveDarkThemeImporter: () => import(/* webpackChunkName: "happySpins-themeWrapLive" */'./HappySpinsLiveDarkThemeImporter'),
    YaacasinoLiveLightThemeImporter: () => import(/* webpackChunkName: "yaacasino-themeWrapLive" */'./YaacasinoLiveLightThemeImporter'),
    MoicasinoLiveLightThemeImporter: () => import(/* webpackChunkName: "moicasino-themeWrapLive" */'./MoiCasinoLiveLightThemeImporter'),
    HeyCasinoLiveDarkThemeImporter: () => import(/* webpackChunkName: "heycasino-themeWrapLive" */'./HeyCasinoLiveDarkThemeImporter'),
    LyraCasinoLiveDarkThemeImporter: () => import(/* webpackChunkName: "lyracasino-themeWrapLive" */'./LyraCasinoLiveDarkThemeImporter'),
    SlotParadiseLiveDarkThemeImporter: () => import(/* webpackChunkName: "slotparadise-themeWrapLive" */'./SlotParadiseLiveDarkThemeImporter'),
    QuickSlotsLiveLightThemeImporter: () => import(/* webpackChunkName: "quickslots-themeWrapLive" */'./QuickSlotsLiveLightThemeImporter'),
    ProntoBetLiveDarkThemeImporter: () => import(/* webpackChunkName: "prontobet-themeWrapLive" */'./ProntoBetLiveDarkThemeImporter'),
  },
};
