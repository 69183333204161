/* eslint-disable no-undef */
import { routes } from '../../config';

let storeDispatch;

export default {
  init(dispatch) {
    storeDispatch = dispatch;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = routes.oddinScript;
    document.body.appendChild(script);
  },
  loadIframe(data, contentElement, errorCallback) {
    if (typeof oddin === 'undefined') return;

    const lang = data.config?.language ?? data.userLanguage;
    const language = lang === 'sr-Latn' || lang === 'sr_Latn' ? 'sr' : lang;

    oddin.buildBifrost({
      token: data.token,
      brandToken: data.config?.brandToken,
      baseUrl: routes.oddinIframe,
      customDomain: data.referrerUrl || routes.appBaseUrl,
      language,
      darkMode: data.conifg?.darkMode || true,
      currency: data.currency,
      contentElement,
      oddsFormat: data.oddsFormat,
      supportedOddsFormats: ['DECIMAL', 'US'],
      route: data.route,
      eventHandler: ({ type }) => {
        switch (type) {
          case 'REQUEST_SIGN_IN':
            storeDispatch('loginRequired');
            break;
          case 'ERROR':
            errorCallback();
            break;
          default:
            console.log('Handle event: ', type);
        }
      },
    });
  },
};
