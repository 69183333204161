export default {
  desktop: {
    ThemeImporter: () => import(/* webpackChunkName: "maxbet-themeWrap" */'./ThemeImporter'),
    LiveThemeImporter: () => import(/* webpackChunkName: "maxbet-themeWrapLive" */'./LiveThemeImporter'),
  },
  mobile: {
    ThemeImporter: () => import(/* webpackChunkName: "maxbet-themeWrap" */'./ThemeImporter'),
    LiveThemeImporter: () => import(/* webpackChunkName: "maxbet-themeWrapLive" */'./LiveThemeImporter'),
  },
};
