import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import VueObserveVisibility from 'vue-observe-visibility';
import vuescroll from 'vuescroll';
import VTooltip from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import smoothscroll from 'smoothscroll-polyfill';
import 'intersection-observer';
import '@/utility/filters';
import App from './App';
import store from './store';
import { gaMixin } from './services/gAnalytics';
import { gTagMixin } from './services/gTag';
import router from './router';
import DynamicComponent from '@/components/common/DynamicComponent';

Vue.use(vClickOutside);
Vue.use(VueObserveVisibility);
Vue.use(vuescroll);
Vue.use(VTooltip);
Vue.use(VueClipboard);
Vue.mixin(gaMixin);
Vue.component('DynamicComponent', DynamicComponent);
Vue.mixin(gTagMixin);
smoothscroll.polyfill();

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
