const date = moment().utc();

export default {
  'filter[from]': date.format(),
  'filter[to]': date.clone().add(7, 'd').endOf('d').format(),
  dataFormat: {
    default: 'array',
    markets: 'array',
    events: 'array',
  },
  language: {
    default: 'en',
  },
  timezone: 'Europe/Monaco',
  company: {},
};
