<template>
  <component
    :is="component"
    v-bind="providedProps"
    v-on="$listeners">
    <slot v-for="(index, name) in $slots" :name="name" :slot="name" />
  </component>
</template>

<script>
import mobileDetect from '@/utility/mobileDetect';
import integrations from '@/components/integrations';
import routes from './componentPathMapper';
import NotFoundComponent from './NotFoundComponent';

export default {
  name: 'DynamicComponent',
  mixins: [mobileDetect],
  props: {
    componentName: {
      type: String,
      required: true,
    },
  },
  computed: {
    companyName() {
      return this.$store.getters.companyName;
    },
    component() {
      return this.integrationComponent || this.defaultComponent || NotFoundComponent;
    },
    platform() {
      return this.$isMobile ? 'mobile' : 'desktop';
    },
    integrationComponent() {
      return this.clientOverrides[this.componentName];
    },
    defaultComponent() {
      return (routes[this.platform] || {})[this.componentName];
    },
    clientOverrides() {
      return (integrations[this.companyName] || {})[this.platform] || {};
    },
    providedProps() {
      if (!this.integrationComponent && !this.defaultComponent) {
        return { componentName: this.componentName };
      }
      return this.$attrs;
    },
  },
};
</script>
