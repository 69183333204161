import axios from 'axios';
import { routes } from '../../config/index';

const http = axios.create({
  baseURL: routes.tc,
  timeout: 10000,
});

const TC_DOMAIN = 'SportsbookSM.Web';
const getUrl = (language, companyUuid) => {
  const lang = `/domains/${TC_DOMAIN}/languages/${language}`;
  return companyUuid ? `${lang}/clients/${companyUuid}` : lang;
};

export default {
  get(language, companyUuid) {
    const url = getUrl(language, companyUuid);
    return http.get(url)
      .then(response => response.data)
      .catch((err) => {
        console.warn('Error getting translations: ', err.toJSON());
        return err.toJSON();
      });
  },
  TC_DOMAIN,
};
