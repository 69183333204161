/**
 * Initiate offer load sequence.
 * @param {Function} storeDispatch
 */

const loadOffer = (storeDispatch, params = {}) => {
  const {
    sport, category, tournament,
  } = params;

  if (sport) {
    storeDispatch('setSportId', sport);
  }
  if (category) {
    storeDispatch('setCategoryId', category);
  }
  if (tournament) {
    storeDispatch('setTournamentId', tournament);
  }
};

const loadTopOffer = (storeDispatch, params = {}) => {
  const {
    sport, tournament,
  } = params;

  if (sport && tournament) {
    storeDispatch('setTopOffer', {
      id: tournament,
      sportId: sport,
    });
  }
};

const loadBonusOffer = (storeDispatch, params = {}) => {
  const { sport } = params;

  // Bonus offer params example (as displayed in 7Web URL):
  // `/3-bonus-bets/123-bonus-bet-name/12345-event-name` (section/sport/category)

  storeDispatch('loadBonusMarketEvents', {
    // Bonus offer requires only 'market_group_id' to load,
    // so use the first param available after 'section'
    marketGroupId: sport,
  });
};

const offerLoadSequence = (storeDispatch, routeParams = {}, handleUrlParams) => {
  let {
    section, sport, category, tournament, event,
  } = routeParams;
  // const { topOffer } = routeParams; // TODO: Deprecate

  // Extract IDs from query params
  section = Number(section?.split('-')[0]);
  sport = Number(sport?.split('-')[0]);
  category = Number(category?.split('-')[0]);
  tournament = Number(tournament?.split('-')[0]);
  event = Number(event?.split('-')[0]);

  if (handleUrlParams) {
    switch (section) {
      case 1:
        loadOffer(storeDispatch, { sport, category, tournament });
        break;
      case 2:
        loadTopOffer(storeDispatch, { sport, tournament });
        break;
      case 3:
        loadBonusOffer(storeDispatch, { sport });
        // To load event in bonus offer, use the second param available after 'section'
        event = category;
        break;
      case 4:
        storeDispatch('setSportId', 9997);
        break;
      default:
        // If invalid section ID, reset param values
        section = null;
        sport = null;
        category = null;
        tournament = null;
        event = null;

        // Remove query params from URL
        storeDispatch('emitRouteChangeToResetParams');
        break;
    }
  }

  storeDispatch('loadMeta', { preSelectedSport: !!sport }) // TODO: Add necessary conditions here
    .then((metaResponse) => {
      // TODO: Should also get updateMasterUrlOnRouteChange, selectedSportId in metaResponse
      const { defaultEventsLoad } = metaResponse;
      const notSportsLoad = defaultEventsLoad !== 'sports' && defaultEventsLoad !== 'bonusSports';
      storeDispatch('loadTopOffer', true)
        .then((response) => {
          // TODO: Deprecate
          // if (topOffer && metaData.tournaments[topOffer]) {
          //   storeDispatch('setTopOffer', metaData.tournaments[topOffer]);
          //   return;
          // }

          const checkForEvent = () => {
            if (event && handleUrlParams) {
              storeDispatch('loadEvent', { id: event });
            }
          };

          if (response.sports.length && notSportsLoad && !section) {
            storeDispatch('selectAllTopOffer').then(() => checkForEvent());
          } else if (section !== 3) {
            storeDispatch('loadFullSportEvents').then(() => checkForEvent());
          }

          if (section === 3) {
            checkForEvent();
          }

          storeDispatch('metaLoading');
        }).catch(() => {
          storeDispatch('metaLoading');
        });
    }).catch(() => {});
};

export default offerLoadSequence;
