import socketio from 'socket.io-client';
import uuidv4 from 'uuid/v4';
import pako from 'pako';
import { routes } from '../../config/index';

let socket = {};
let storeDispatch = null;
let cpv = null;
let lang = null;
let aliveId = null;

const decompress = (source) => {
  try {
    return JSON.parse(pako.inflate(source, {
      to: 'string',
    }));
  } catch (e) {
    console.warn('Invalid data received => ', e);
    return {};
  }
};

export default {
  instance({
    dispatch, cpvUuid, language,
  }) {
    storeDispatch = dispatch;
    cpv = cpvUuid;
    lang = language || 'en';
    this.connect();
  },
  connect() {
    const url = routes.liveSocket;
    const connUuid = uuidv4();

    if (!cpv) return;

    const socketOptions = {
      transports: ['websocket'],
      query: `cpv=${cpv}&EIO&product=live&protocol=sio1&contentEncoding=deflate&connUuid=${connUuid}`,
    };

    socket = socketio(url, socketOptions);
    socket.connect();

    socket.on('connect', () => {
      console.log('live socket connected');
      this.liveSubscribe();
    });

    socket.on('message', ({ data, type, meta }) => {
      const messageData = meta.contentEncoding === 'deflate' ? decompress(data) : data;

      if (type === 'eventCount') {
        storeDispatch('setLiveMatchesCount', messageData?.numberOfLiveEvents);
      }

      if (type === 'serviceAlive') {
        storeDispatch('serviceAlive');
      }
    });
    socket.on('disconnect', () => {
      console.log('live socket disconnected');
      if (socket && aliveId) clearInterval(aliveId);
    });
  },
  serviceAlive() {
    socket.emit('message', {
      type: 'serviceAlive',
    });
  },
  liveSubscribe() {
    socket.emit('subscribe', {
      language: lang,
      deliveryPlatform: 'WebEventCountSubscribe',
    });

    // TODO get this value passed or some other way
    // const clientInterval = storeGetters.getClientTimeout;
    const clientInterval = 5000;

    aliveId = setInterval(() => {
      this.serviceAlive();
    }, clientInterval);
  },
  disconnect() {
    socket.disconnect();
  },
  reconnect() {
    if (socket && socket.disconnect) {
      socket.disconnect();
      this.connect();
    }
  },
};
