import Vue from 'vue';
import VueRouter from 'vue-router';
import sbsmclientstore from '@nsftx/sb-sm-client-store';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    // default and root routes should be removed once we have product routes
    {
      path: '/',
      name: 'default',
      component: () => import('@/components/prematch/Prematch'),
      props: true,
      beforeEnter(to, from, next) {
        if (sbsmclientstore) {
          // Pass your store and name to be registered as sb-sm-client-store module
          sbsmclientstore(store, 'mainStore');
        }
        import(/* webpackChunkName: "prematchModule" */'@/store/modules/prematch')
          .then((importedModule) => {
            if (!store.state.prematch) store.registerModule('prematch', importedModule.default);
            next();
          });
      },
    },
    {
      path: '/prematch',
      name: 'Prematch',
      component: () => import('@/components/prematch/Prematch'),
      props: true,
      beforeEnter(to, from, next) {
        if (sbsmclientstore) {
          // Pass your store and name to be registered as sb-sm-client-store module
          sbsmclientstore(store, 'mainStore');
        }
        import(/* webpackChunkName: "prematchModule" */'@/store/modules/prematch')
          .then((importedModule) => {
            if (!store.state.prematch) store.registerModule('prematch', importedModule.default);
            next();
          });
      },
    },
    {
      path: '/prematch/ticket-preview/:id',
      name: 'PrematchTicketPreview',
      component: () => import('@/components/common/StandaloneTicketPreview'),
      props: true,
    },
    {
      path: '/prematch/ticket-history',
      name: 'PrematchTicketHistory',
      component: () => import('@/components/common/standalone-ticket-history/StandaloneTicketHistory'),
      props: true,
      beforeEnter(to, from, next) {
        import(/* webpackChunkName: "prematchModule" */'@/store/modules/prematch')
          .then((importedModule) => {
            if (!store.state.prematch) store.registerModule('prematch', importedModule.default);
            next();
          });
      },
    },
    {
      path: '/prematch/ticket-check',
      name: 'PrematchTicketCheck',
      component: () => import('@/components/common/standalone-ticket-details/StandaloneTicketDetails'),
      props: true,
      beforeEnter(to, from, next) {
        import(/* webpackChunkName: "prematchModule" */'@/store/modules/prematch')
          .then((importedModule) => {
            if (!store.state.prematch) store.registerModule('prematch', importedModule.default);
            next();
          });
      },
    },
    {
      path: '/live',
      name: 'Live',
      component: () => import(/* webpackChunkName: "liveComponent" */'@/components/live/Live'),
      props: true,
    },
    {
      path: '/live/ticket-history',
      name: 'LiveTicketHistory',
      component: () => import('@/components/live/common/standalone-player-ticket/TicketHistory'),
      props: true,
      beforeEnter(to, from, next) {
        import(/* webpackChunkName: "liveComponent" */'@/store/modules/live')
          .then((importedModule) => {
            if (!store.state.live) store.registerModule('live', importedModule.default);
            next();
          });
      },
    },
    {
      path: '/live/ticket-details',
      name: 'LiveTicketDetails',
      component: () => import('@/components/live/common/standalone-player-ticket/TicketDetails'),
      props: true,
      beforeEnter(to, from, next) {
        import(/* webpackChunkName: "liveComponent" */'@/store/modules/live')
          .then((importedModule) => {
            if (!store.state.live) store.registerModule('live', importedModule.default);
            next();
          });
      },
    },
  ],
});

export default router;
