const getStorageType = (type) => {
  let storageType;

  if (type === 'sessionStorage') {
    try {
      storageType = window.sessionStorage;
    } catch (error) { console.log('session not supported'); }
  } else {
    try {
      storageType = localStorage;
    } catch (error) { console.log('localstorage not supported'); }
  }

  return storageType || {};
};

export default {
  setItem(key, data, storageType = 'localStorage') {
    try {
      return getStorageType(storageType)?.setItem(key, data);
    } catch (error) {
      return null;
    }
  },
  removeItem(key, storageType = 'localStorage') {
    try {
      return getStorageType(storageType)?.removeItem(key);
    } catch (error) {
      return null;
    }
  },
  getItem(key, storageType = 'localStorage') {
    try {
      return getStorageType(storageType)?.getItem(key);
    } catch (error) {
      return null;
    }
  },
};
