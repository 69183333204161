export default {
  desktop: {
    LiveThemeImporter: () => import(/* webpackChunkName: "stanleybet-themeWrapLive" */'./LiveThemeImporter'),
    ThemeImporter: () => import(/* webpackChunkName: "stanleybet-themeWrap" */'./ThemeImporter'),
  },
  mobile: {
    LiveThemeImporter: () => import(/* webpackChunkName: "stanleybet-themeWrapLive" */'./LiveThemeImporter'),
    ThemeImporter: () => import(/* webpackChunkName: "stanleybet-themeWrap" */'./ThemeImporter'),
  },
};
